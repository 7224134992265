<template>
  <InnerScroll :transparent="true" :full-height="true">
    <the-chat
      :current-user-chat="currentUserChat"
      :chat-title="chatTitle"
      :has-active-chat="hasActiveChat"
      :show-closed-message="showClosedMessage"
      @closed-chat-info-read="handleClosedChatInfoRead"
      @close-chat="handleCloseChat"
      @cancel-chat="handleCancelChat"
    />
  </InnerScroll>
</template>

<script>
import { mapGetters } from 'vuex'
import TheChat from 'organisms/TheChat/TheChat'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  components: {
    TheChat,
    InnerScroll
  },

  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters([
      'currentUserChat',
      'currentChatEvent',
      'hasActiveChat',
      'showClosedMessage'
    ]),
    chatTitle() {
      let title = this.$t('chat.newChatTitle', {
        topic: this.currentChatEvent ? this.currentChatEvent.subject : ''
      })
      if (this.currentUserChat !== null) {
        switch (this.currentUserChat.status) {
          case 'pending':
            title = this.$t('chat.waitingForChatAssignment')
            title += ` Position ${this.currentUserChat.waitingPosition} von ${this.currentUserChat.waitingQueue}`
            break
          case 'active':
            title = `${this.$t('chat.title')} ${this.currentUserChat.counselor}`
            break
        }
      }
      return title
    }
  },
  methods: {
    handleClosedChatInfoRead() {
      this.redirectToChatDialogs()
    },
    handleCloseChat() {
      this.redirectToChatDialogs()
    },
    handleCancelChat() {
      this.redirectToChatDialogs()
    },
    redirectToChatDialogs() {
      this.$router.push({ name: 'chatDialogs' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/LiveChat';
</style>
